






























import { NodeInfo } from '@/types/state'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class DefaultHome extends Vue {
    @Prop()
    title!: string

    @Prop()
    headers!: string[]

    @Prop()
    items!: string[]

    @Prop()
    icon?: string
    
    get userRole(): string {
        return this.$store.getters['identity/role']
    }

    get isModOrAdmin(): boolean {
        return this.userRole == 'Moderator' || this.userRole == 'Admin'
    }
}
